import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { useDrawer } from "../../context/drawer";
import UpdateForm from "../Forms/Client/update-form";
import AddForm from "../Forms/Client/add-form";
import PackageUpdateForm from "../Forms/Packages/update-package";
import PackageAddForm from "../Forms/Packages/add-package";
import AddEmployee from "../Forms/Employees/add-employee";
import UpdateEmployee from "../Forms/Employees/update-employee";
import BookingView from "../Forms/Booking/booking-view";
import UpdateAccount from "../Forms/Account/update-form";
import AddAccount from "../Forms/Account/add-form";
import AddBank from "../Forms/Banks/add-form";
import AddEnachForm from "../Forms/eNach/add-form";
import UpdateBank from "../Forms/Banks/update-form";
import EnachView from "../Forms/eNach/eNach-view";
import AddServices from "../Forms/Services/add-services";
import UpdateService from "../Forms/Services/update-services";

const DrawerComp = () => {
  const { drawerData, toggleDrawer } = useDrawer();
  const [listData, setListData] = useState(null);

  useEffect(() => {
    if (drawerData.value !== null) {
      setListData(drawerData.value);
    }
  }, [drawerData]);

  return (
    <>
      <Drawer
        title={drawerData.title}
        placement="right"
        width={drawerData.type === "enachCreate" ? 500 : 500}
        onClose={toggleDrawer}
        open={drawerData.isOpen}
      >
        {drawerData.type === "clientUpdate" ? (
          <UpdateForm listData={listData} />
        ) : null}

        {drawerData.type === "clientAdd" ? <AddForm /> : null}

        {drawerData.type === "packageUpdate" ? <PackageUpdateForm /> : null}
        {drawerData.type === "packageAdd" ? <PackageAddForm /> : null}
        {drawerData.type === "employeeAdd" ? <AddEmployee /> : null}
        {drawerData.type === "employeeUpdate" ? <UpdateEmployee /> : null}
        {drawerData.type === "bookingView" ? <BookingView /> : null}
        {drawerData.type === "accountUpdate" ? <UpdateAccount /> : null}
        {drawerData.type === "accountAdd" ? <AddAccount /> : null}
        {drawerData.type === "enachCreate" ? <AddEnachForm /> : null}
        {drawerData.type === "bankAdd" ? <AddBank /> : null}
        {drawerData.type === "bankUpdate" ? <UpdateBank /> : null}
        {drawerData.type === "viewEnach" ? <EnachView /> : null}
        {drawerData.type === "serviceAdd" ? <AddServices /> : null}
        {drawerData.type === "serviceUpdate" ? <UpdateService /> : null}
      </Drawer>
    </>
  );
};
export default DrawerComp;
